<template>
  <b-card :title="titleForm">
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>

          <!-- first name -->
          <b-col
            cols="12"
            lg="6"
          >
            <validation-provider
              #default="{ errors }"
              name="nº protocolo"
              rules="required"
            >
              <b-form-group
                label="Nº protocolo"
                label-for="v-numProtocolo"
              >
                <b-form-input
                  id="v-numProtocolo"
                  v-model="form.numeroProtocolo"
                  :state="errors.length > 0 ? false:null"
                  placeholder="descrição"
                />
                <small
                  data-cy="v-numProtocolo-validate"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- CPF -->
          <b-col
            v-if="!this.form.id"
            cols="12"
            lg="6"
          >
            <validation-provider
              #default="{ errors }"
              name="cpf"
              rules="required"
            >
              <b-form-group
                label="CPF"
                label-for="v-cpf"
              >
                <cleave
                  id="v-cpf"
                  v-model="cpf"
                  class="form-control"
                  :class="[(errors.length > 0) ? isValid : '']"
                  :raw="false"
                  placeholder="apenas números"
                  :options="maskCpf"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Nome"
              label-for="v-nome"
            >
              <b-form-input
                id="v-nome"
                v-model="nomeRegistroEncontrado"
                placeholder="nome registro"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- Data Adesao -->
          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label="Data Adesão"
              label-for="v-dataAdesao"
            >

              <flat-pickr
                v-model="form.dataProtocolo"
                name="fieldDate"
                :config="{ altInput: true, altFormat: 'd/m/Y', enableTime: false, dateFormat: 'Y-m-d\\TH:i:ss\\Z', }"
              />
            </b-form-group>
          </b-col>

          <!-- Data Adesao -->
          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label="Data Adesão"
              label-for="v-dataAdesao"
            >

              <flat-pickr
                v-model="form.dataVencimento"
                name="fieldDate"
                :config="{ altInput: true, altFormat: 'd/m/Y', enableTime: false, dateFormat: 'Y-m-d\\TH:i:ss\\Z', }"
              />
            </b-form-group>
          </b-col>

          <!--Nacionalidade -->
          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label="Tipo Protocolo"
              label-for="v-tipo"
            >

              <v-select
                v-model="form.tipo"
                label="descricao"
                :options="comboTipoProtocolo"
              />
            </b-form-group>
          </b-col>

          <!--Nacionalidade -->
          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label="Meio Protocolo"
              label-for="v-meio"
            >

              <v-select
                v-model="form.meio"
                label="descricao"
                :options="comboMeioProtocolo"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="form.id"
            cols="12"
          >
            <b-form-group
              label="Ativo"
              label-for="v-check-button"
            >
              <b-form-checkbox
                v-model="form.ativo"
                :checked="form.ativo"
                name="check-button"
                switch
                inline
              >
                {{ form.ativo ? 'Sim' : 'Não' }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <label for="textarea-default">Observações</label>
            <b-form-textarea
              id="textarea-default"
              v-model="form.observacao"
              class="mb-1"
              placeholder="Observações"
              rows="3"
            />
          </b-col>

          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              data-cy="save"
              @click="save"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              data-cy="cancel"
              @click="cancel"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BFormCheckbox, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import mixinsGlobal from '@/mixins'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

export default {
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('protocolSettingsModule', {
      protocol: state => state.protocol,
    }),
    ...mapState('combosModule', {
      comboTipoProtocolo: state => state.comboTipoProtocolo,
      comboMeioProtocolo: state => state.comboMeioProtocolo,
    }),
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    vSelect,
    flatPickr,
    Cleave,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      titleForm: 'Cadastro de Protocolo',
      isValid: 'is-invalid',
      required,
      cpf: '',
      nomeRegistroEncontrado: '',
      form: {
        numeroProtocolo: '',
        dataProtocolo: null,
        dataVencimento: null,
        tipo: null,
        meio: null,
        registro: null,
        ativo: true,
        observacao: '',
      },
      maskCpf: {
        delimiters: ['.', '.', '-'],
        blocks: [3, 3, 3, 2],
        uppercase: true,
        onValueChanged: this.getRegistroByCpf.bind(this),
      },
    }
  },
  async mounted() {
    await this.$store.dispatch('combosModule/loadComboTipoProtocolo')
    await this.$store.dispatch('combosModule/loadComboMeioProtocolo')
    this.cpf = ''
    this.nomeRegistroEncontrado = ''
    this.form = JSON.parse(JSON.stringify(this.protocol))
    if (this.form.id) {
      this.nomeRegistroEncontrado = this.form.registro.nome
    }
  },
  methods: {
    cancel() {
      this.$emit('cancelar')
      this.cleanObjeto()
    },
    save() {
      this.$refs.form.validate().then(success => {
        if (success) {
          if (this.form.id) {
            this.$store.dispatch('protocolSettingsModule/edit', this.form).then(response => {
              if (response.response) {
                this.MensagemError('Falha ao Editar', response.response.data.error)
                return
              }
              this.showMessageSuccess('Editar', 'Editado com sucesso!')
              this.$refs.form.reset()
              this.cancel()
            })
          } else {
            this.$store.dispatch('protocolSettingsModule/insert', this.form).then(response => {
              if (response.response) {
                this.MensagemError('Falha ao Inserir', response.response.data.error)
                return
              }
              this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
              this.cleanObjeto()
              this.$refs.form.reset()
            })
          }
        }
      })
    },
    cleanObjeto() {
      this.form = {
        numeroProtocolo: '',
        dataProtocolo: null,
        dataVencimento: null,
        tipo: null,
        meio: null,
        registro: null,
        ativo: true,
        observacao: '',
      }
    },
    async getRegistroByCpf() {
      if (this.cpf && this.cpf.length === 14) {
        const registroEncontrado = await this.$store.dispatch('pagamentoRecebimentoFinancialModule/getAssocianteForCpf', this.cpf)
        if (registroEncontrado.response) {
          this.MensagemError('Falha na busca', `${registroEncontrado.response.data.message}`)
          this.nomeRegistroEncontrado = ''
          return
        }
        this.form.registro = { id: registroEncontrado.data.id }
        this.nomeRegistroEncontrado = registroEncontrado.data.nome
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
