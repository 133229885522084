<template>
  <b-row>
    <b-col cols="12">
      <TableComponents
        v-if="!showForm"
        :columns="columns"
        :rows="listProtocol"
        title-table="Protocolos"
        module="protocolSettingsModule"
        sort-name="numeroProtocolo"
        @add="addNew"
        @edit="edit"
      />
      <FormProtocol
        v-else
        @cancelar="cancelar"
      />
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from 'vuex'
import TableComponents from '@/layouts/components/TableComponents.vue'
import { BRow, BCol } from 'bootstrap-vue'
import mixinsGlobal from '@/mixins'
import FormProtocol from '../form/FormProtocol.vue'

export default {
  components: {
    BRow,
    BCol,
    TableComponents,
    FormProtocol,
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('protocolSettingsModule', {
      listProtocol: state => state.listProtocol,
    }),
  },
  data() {
    return {
      showForm: false,
      columns: [
        {
          label: 'Id',
          field: 'id',
        },
        {
          label: 'Nº Protocolo',
          field: 'numeroProtocolo',
        },
        {
          label: 'Nome',
          field: 'registro.nome',
        },
        {
          label: 'Data Protocolo',
          field: 'dataProtocolo',
          formatFn: this.formatDateGrid,
        },
        {
          label: 'Tipo',
          field: 'tipo.descricao',
        },
        {
          label: 'Meio Protocolo',
          field: 'meio.descricao',
        },
        {
          label: 'Ativo',
          field: 'ativo',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      pageConfig: {
        page: 1,
        size: 10,
        sort: 'descricao',
        order: 'asc',
      },
    }
  },
  methods: {
    addNew() {
      this.showForm = true
    },
    edit(data) {
      this.$store.dispatch('protocolSettingsModule/prepareToEdit', data)
      this.showForm = true
    },
    cancelar() {
      this.showForm = false
    },
  },
}
</script>
